import {useState, useEffect} from 'react';
import {setItem, getItem} from 'utils/localStorage';
import forEach from 'lodash/forEach';
import indexOf from 'lodash/indexOf';

export const useInput = initialValue => {
  const [value, setValue] = useState(initialValue);
  const handleChange = e => {
    setValue(e.target.value);
  };

  return {value, handleChange};
};
export const useCheck = initialChecked => {
  const [checked, setChecked] = useState(initialChecked);
  const handleChange = e => {
    setChecked(e.target.checked);
  };

  return {checked, handleChange};
};
export const useFocusBlur = props => {
  const {autoFocus, handleFocus, handleBlur} = props;
  const [focus, setFocus] = useState(autoFocus);

  return {
    focus,
    handleFocus: e => {
      if (handleFocus) {
        handleFocus(e);
      }
      setFocus(true);
    },
    handleBlur: e => {
      if (handleBlur) {
        handleBlur(e);
      }
      setFocus(false);
    },
  };
};
export const useToggle = initialValue => {
  const [visible, setVisible] = useState(initialValue);
  const handleToggle = () => {
    setVisible(!visible);
  };

  return {
    visible,
    handleToggle,
    toggleAlt: visible ? 'Hide' : 'Show',
    toggleAltContent: `${visible ? 'Hide' : 'Show'} content`,
  };
};

export const useDeleteTableRows = initialValue => {
  const [deletedRowIds, setDeletedRows] = useState(initialValue);
  const deleteRow = rowId => {
    setDeletedRows([...deletedRowIds, rowId]);
  };
  const clearDeletedRows = () => {
    setDeletedRows([]);
  };
  const getVisibleAndDeletedRows = trData => {
    const deletedRows = [];
    const visibleRows = [];

    forEach(trData, obj => {
      if (indexOf(deletedRowIds, obj.id) === -1) {
        visibleRows.push(obj);
      } else {
        deletedRows.push(obj);
      }
    });

    return {
      deletedRows,
      visibleRows,
    };
  };

  return {
    deletedRowIds,
    deleteRow,
    clearDeletedRows,
    getVisibleAndDeletedRows,
  };
};

export const useLocalStorage = key => {
  const [item, setItemState] = useState(getItem(key));
  const handleSetItemState = newItem => {
    setItemState(newItem);
    setItem(key, newItem);
  };

  return {
    item,
    handleSetItemState,
  };
};
export const useOffClickEffect = initialValue => {
  const {visible, toggleAlt, handleToggle} = useToggle(initialValue);

  useEffect(() => {
    const listener = () => {
      if (visible) {
        handleToggle();
      }
    };
    if (document) {
      document.addEventListener('click', listener);
    }

    return () => {
      document.removeEventListener('click', listener);
    };
  });

  return {
    visible,
    handleToggle,
    toggleAlt,
  };
};
