import React from 'react';
import {graphql} from 'gatsby';
import Card from 'components/blocks/card';
import StaticLayout from 'components/layouts/static-layout';
import {Row} from 'components/wrappers';
import PropTypes from 'prop-types';

const Demo = ({data}) => {
  return (
    <StaticLayout
      {...{
        contentPadding: true,
        container: true,
        seo: {
          title: 'Demo page',
          description: 'Demo page of different apps',
        },
      }}
    >
      <h1 className="sr-only">Demo</h1>
      <Row>
        <div className="col-lg-6">
          <Card title="Pokemon">
            <table className="table table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {data.allPokemon.nodes.map(({name, type, id}) => (
                  <tr key={id}>
                    <td>{id}</td>
                    <td>{name}</td>
                    <td>{type}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
        <div className="col-lg-6">
          <Card title="Pokemon">
            <table className="table table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {data.allPokemon.nodes.map(({name, type, id}) => (
                  <tr key={id}>
                    <td>{id}</td>
                    <td>{name}</td>
                    <td>{type}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </Row>
    </StaticLayout>
  );
};

Demo.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
    allPokemon: PropTypes.object,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export const GATSBY_QUERY = graphql`
  query PokemonQuery {
    allPokemon {
      nodes {
        name
        type
        id
      }
    }
  }
`;

export default Demo;
