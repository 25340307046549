import {isBrowser} from 'utils';

const id = process.env.GATSBY_LOCAL_STORAGE_ID;

export const getLocalStorageKey = key => `${id}-${key}`;

export const setItem = (key, obj) => {
  if (!isBrowser) {
    return;
  }
  try {
    localStorage.setItem(getLocalStorageKey(key), JSON.stringify(obj));
  } catch (ex) {
    console.error(ex);
  }
};

export const getItem = key => {
  if (!isBrowser) {
    return;
  }
  try {
    let val = localStorage.getItem(getLocalStorageKey(key));
    val = JSON.parse(val);
    return val;
  } catch (ex) {
    console.error(ex);
  }
};
